import React, { Suspense, useContext, useImperativeHandle, useRef } from "react";
import { Box as MUIBox, CircularProgress } from "@mui/material";
import { Canvas } from "@react-three/fiber";
import { Object3D } from "three";
import Grid from "./Grid";
import { CollabContext } from "../CollabProvider";
import ViewRControls, { ViewRControlsRef } from "./ViewRControls";
import Model from "./Model";
import RenderEffects from "./RenderEffects";

export type Viewport3DRef = {
  fitCameraToModel: (fitFac?: number) => void;
};

type Viewport3DProps = {
  modelCode: string | undefined;
  enableGrid: boolean;
  enableAO: boolean;
  enableMeasure: boolean;
};

const Viewport3D = React.forwardRef<Viewport3DRef, Viewport3DProps>(({ modelCode, enableGrid, enableAO, enableMeasure }: Viewport3DProps, ref) => {
  const controlsRef = useRef<ViewRControlsRef>(null);
  const modelGroupRef = useRef<Object3D>(null);
  useImperativeHandle(ref, () => ({
    fitCameraToModel: () => controlsRef.current?.fitCameraToModel(),
  }));
  const collabContext = useContext(CollabContext);
  return (
    // @ts-ignore
    <MUIBox
      // @ts-ignore
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Suspense fallback={<CircularProgress color="secondary" />}>
        <Canvas
          gl={{
            logarithmicDepthBuffer: true,
            antialias: false,
          }}
          onCreated={state => {
            state.gl.autoClear = false;
          }}
          frameloop="demand"
          flat
          linear
          style={{
            touchAction: "none",
          }}
          dpr={window.devicePixelRatio ?? 1}
        >
          <CollabContext.Provider value={collabContext}>
            <RenderEffects enabled={enableAO} />
            <ViewRControls
              ref={controlsRef}
              modelGroupRef={modelGroupRef}
            />
            <group ref={modelGroupRef}>
              <Model
                modelCode={modelCode}
                enableMeasure={enableMeasure}
                controlsRef={controlsRef}
              />
            </group>
            <Grid visible={enableGrid} />
          </CollabContext.Provider>
        </Canvas>
      </Suspense>
    </MUIBox>
  );
});

export default Viewport3D;
