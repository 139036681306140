import React, { useContext, useEffect, useLayoutEffect, useRef, useState } from "react";
import { Box, Card, CardActionArea, Divider, Paper, Stack, Typography, useTheme, lighten, useMediaQuery } from "@mui/material";
import ArrowUpIcon from "@mui/icons-material/ArrowDropUp";
import ArrowDownIcon from "@mui/icons-material/ArrowDropDown";
import useResizeObserver from "@react-hook/resize-observer";
// import iconCut from "../icons/Cutplane.png";
import iconFitViewport from "../icons/Fit_Viewport.png";
import iconGrid from "../icons/GRID.png";
import iconSoftShadows from "../icons/SOFTSHADOWS.png";
import iconConnect from "../icons/connect.png";
import iconDisconnect from "../icons/disconnect.png";
import { CollabContext } from "../CollabProvider";
// import iconMeasure from "../icons/Measure.png";

function TopBarButton({ children, icon, active, onClick, disabled }: { children: React.ReactNode; icon: string; active?: boolean; onClick?: () => void; disabled?: boolean }) {
  const theme = useTheme();
  const downSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Card sx={{ backgroundColor: active ? lighten(theme.palette.background.paper, 0.1) : undefined, color: active ? theme.palette.secondary.main : undefined, border: "none" }} variant="outlined">
      <CardActionArea disabled={disabled} sx={{ p: 1 }} onClick={() => onClick?.()}>
        <Stack
          sx={{
            width: downSm ? "50px" : "75px",
            height: "100px",
          }}
          alignItems="center"
        >
          {/* @ts-ignore */}
          <Box
            // @ts-ignore
            sx={{
              flexGrow: 1,
              alignSelf: "stretch",
              backgroundImage: `url(${icon})`,
              backgroundSize: "contain",
              backgroundPosition: "center",
              backgroundRepeat: "no-repeat",
              m: 1,
            }}
          />
          <Typography sx={{ textAlign: "center" }} variant="caption">
            {children}
          </Typography>
        </Stack>
      </CardActionArea>
    </Card>
  );
}

function TopBarGroup({ title, children }: { title: string; children: React.ReactNode }) {
  return (
    <Stack alignItems="center" spacing={2}>
      <Stack direction="row" spacing={2}>
        {children}
      </Stack>
      <Typography variant="caption">
        {title}
      </Typography>
    </Stack>
  );
}

const useSize = (target: React.RefObject<HTMLDivElement>) => {
  const [size, setSize] = useState<DOMRect>();

  useLayoutEffect(() => {
    setSize(target.current?.getBoundingClientRect());
  }, [target]);

  useResizeObserver(target, (entry) => setSize(entry.contentRect));
  return size;
}

export default function TopBar({ enableGrid, onEnableGrid, enableAO, onEnableAO, enableMeasure, onEnableMeasure, onFitViewport }: { enableGrid: boolean; onEnableGrid: (enable: boolean) => void; enableAO: boolean; onEnableAO: (enable: boolean) => void; enableMeasure: boolean; onEnableMeasure: (enable: boolean) => void; onFitViewport: () => void }) {
  const [topBarVisible, setTopBarVisible] = useState(true);
  const theme = useTheme();

  const topBarRef = useRef<HTMLDivElement>(null);
  const size = useSize(topBarRef);

  const collabContext = useContext(CollabContext);
  const [collabStatus, setCollabStatus] = useState<"unknown" | "active" | "inactive">("unknown");
  useEffect(() => {
    const sub = collabContext.syncWebData$.subscribe(syncWebData => {
      setCollabStatus(syncWebData.status);
    });
    return () => {
      sub.unsubscribe();
    };
  }, [collabContext.syncWebData$]);

  return (
    <Stack
      sx={{
        position: "absolute",
        top: topBarVisible ? 0 : `-${size?.height}px`,
        transition: "top 0.25s ease-in-out",
        left: 0,
        right: 0,
      }}
      alignItems="center"
    >
      <Paper
        ref={topBarRef}
        sx={{
          alignSelf: "stretch",
          borderRadius: "0 0 4px 4px",
          width: "100%",
          height: "100%",
          overflow: "auto",
        }}
        color=""
      >
        <Stack
          direction="row"
          spacing={3}
          sx={{
            px: 3,
            py: 1,
          }}
        >
          <TopBarGroup
            title="General"
          >
            {/* <TopBarButton icon={iconCut}>
              Schnittfläche<br />&nbsp;
            </TopBarButton> */}
            <TopBarButton icon={iconFitViewport} onClick={() => onFitViewport()}>
              Fit<br />Viewport
            </TopBarButton>
          </TopBarGroup>
          <Divider orientation="vertical" flexItem />
          <TopBarGroup
            title="View"
          >
            <TopBarButton icon={iconGrid} active={enableGrid} onClick={() => onEnableGrid(!enableGrid)}>
              Grid<br />&nbsp;
            </TopBarButton>
            <TopBarButton icon={iconSoftShadows} active={enableAO} onClick={() => onEnableAO(!enableAO)}>
              Soft<br />Shadows
            </TopBarButton>
          </TopBarGroup>
          {
            collabStatus === "active" ? (
              <>
                <Divider orientation="vertical" flexItem />
                <TopBarGroup
                  title="Collaboration"
                >
                  <TopBarButton icon={collabContext.connectCollab ? iconDisconnect : iconConnect} active={collabContext.connectCollab} onClick={() => collabContext.setConnectCollab(!collabContext.connectCollab)} disabled={collabStatus !== "active"}>
                    {collabContext.connectCollab ? "Disconnect" : "Connect"}<br />&nbsp;
                  </TopBarButton>
                </TopBarGroup>
              </>
            ) : null
          }
          <Divider orientation="vertical" flexItem />
          {/* <TopBarGroup
            title="Review"
          >
            <TopBarButton icon={iconMeasure} active={enableMeasure} onClick={() => onEnableMeasure(!enableMeasure)}>
              Messen<br />&nbsp;
            </TopBarButton>
          </TopBarGroup> */}
        </Stack>
      </Paper>
      <Card
        sx={{
          borderRadius: theme.spacing(0, 0, 1, 1),
        }}
      >
        <CardActionArea
          sx={{
            px: 2,
          }}
          onClick={() => setTopBarVisible(x => !x)}
        >
          {
            topBarVisible ? <ArrowUpIcon /> : <ArrowDownIcon />
          }
        </CardActionArea>
      </Card>
    </Stack>
  );
}
