import React, { useRef } from "react";
import { Plane, shaderMaterial } from "@react-three/drei";
import { Color, DoubleSide } from "three";
import { extend, invalidate } from "@react-three/fiber";
import { useSpring } from "react-spring";

const axes = "xzy";
const planeAxes = "xz";

const MyMaterial = shaderMaterial(
  {
    gridSize1: 1,
    gridDistance: 100,
    gridColor: new Color("#4d4b4c").convertSRGBToLinear(),
    scaleFac: 1.0,
  },
  `
    varying vec3 worldPosition;
    varying float distToCamera;
    uniform float gridDistance;
    uniform float scaleFac;
    
    void main() {
      vec3 pos = position.${axes} * gridDistance * 9999.0;
      pos.${planeAxes} += cameraPosition.${planeAxes};
      worldPosition = pos;
      gl_Position = projectionMatrix * modelViewMatrix * vec4(pos, 1.0);

      vec4 cs_position = modelViewMatrix * vec4(pos, 1.0);
      distToCamera = -cs_position.z;
    }
  `,
  `
    varying vec3 worldPosition;
    varying float distToCamera;
    uniform float gridSize1;
    uniform vec3 gridColor;
    uniform float gridDistance;

    float map(float value, float inMin, float inMax, float outMin, float outMax) {
      float val = outMin + (outMax - outMin) * (value - inMin) / (inMax - inMin);
      return val;
    }

    float getBlurCamHeightFactor() {
      return max(min(map(abs(cameraPosition.y), 0.0, 25.0, 1.0, 0.0), 1.0), 0.0);
    }
    float getBlurDistanceFactor() {
      return pow(max(min(map(distToCamera, 1.0, 50.0, 0.0, 1.0), 1.0), 0.0), 2.0);
    }
    float getBlurAngleFactor() {
      vec3 camUp = (inverse(viewMatrix) * vec4(0.0, 1.0, 0.0, 1.0)).xyz - cameraPosition;
      float camFlatness = 1.0 - abs(dot(camUp, vec3(0.0, 1.0, 0.0)));
      return pow(max(min(map(camFlatness, 0.0, 0.25, 1.0, 0.0), 1.0), 0.0), 3.0);
    }
    float getBlurFactor() {
      return 0.5 * (getBlurAngleFactor() * 0.005 + getBlurDistanceFactor() * 0.5 + getBlurCamHeightFactor() * 0.1);
    }

    float getGrid(float size) {
      float thickness = 0.015;
      vec2 r = worldPosition.${planeAxes} / size;
      vec2 v = abs(fract(r) - 0.5) * 2.0;
      vec2 fw = fwidth(v);
      float blurFac = getBlurFactor();
      float camFac = max(min(map(distToCamera, 1.0, 10.0, 0.0, blurFac), blurFac), 0.0);
      vec2 ret = vec2(smoothstep(1.0-thickness-fw.x-camFac, 1.0-thickness+fw.x, v.x), smoothstep(1.0-thickness-fw.y-camFac, 1.0-thickness+fw.y, v.y));
      return max(min(ret.x + ret.y, 1.0), 0.0);
    }

    void main() {
      float d = 1.0 - min(distToCamera / gridDistance, 1.0);

      float g1 = getGrid(gridSize1);

      gl_FragColor = linearToOutputTexel(vec4(gridColor.rgb, g1) * pow(d, 3.0));
      gl_FragColor.a = mix(0.0, gl_FragColor.a, g1);
    }
  `,
  mat => {
    if (!mat) return;
    mat.transparent = true;
    mat.side = DoubleSide;
  }
);

extend({ MyMaterial });

type MyMaterialImpl = {
  gridSize1?: number;
  gridDistance?: number;
  gridColor?: number;
  scaleFac?: number;
} & JSX.IntrinsicElements["shaderMaterial"];

declare global {
  namespace JSX {
    interface IntrinsicElements {
      myMaterial: MyMaterialImpl;
    }
  }
}

export default function Grid({ visible }: { visible: boolean }) {
  const ref = useRef<MyMaterialImpl>();
  useSpring({
    opacity: visible ? 1 : 0,
    onChange: val => {
      if (!ref.current?.uniforms) return;
      ref.current.uniforms.gridDistance.value = val.value.opacity * 100;
      invalidate();
    },
  });
  const scaleFac = 0.001;
  return (
    <Plane args={[scaleFac, scaleFac, 1, 1]} frustumCulled={false}>
      <myMaterial
        ref={ref}
        attach="material"
        scaleFac={scaleFac}
      />
    </Plane>
  );
}
