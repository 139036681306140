import React, { useRef, useState } from "react";
import { Alert, AlertTitle, Box, Button, Link, Stack, ThemeProvider } from "@mui/material";
import { useParams } from "react-router-dom";
import TopBar from "../../components/TopBar";
import Viewport3D, { Viewport3DRef } from "../../components/Viewport3D";
import useThemeOptions from "../../useThemeOptions";
import { ErrorBoundary, FallbackProps } from "react-error-boundary";
import CollabProvider from "../../CollabProvider";

function ErrorFallback({ error, resetErrorBoundary }: FallbackProps) {
  return (
    // <div role="alert">
    //   <p>Something went wrong:</p>
    //   <pre>{error.message}</pre>
    //   <button >Try again</button>
    // </div>

    <Stack
      sx={{
        width: "100%",
        height: "100%",
      }}
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Alert severity="error">
        <AlertTitle>
          Something went wrong
        </AlertTitle>
        <pre>
          {error}
        </pre>
      </Alert>
      <Button
        variant="outlined"
        color="secondary"
        onClick={resetErrorBoundary}
      >
        Try again
      </Button>
      <Link
        color="secondary"
        href="/"
        sx={{
          textDecoration: "none",
        }}
      >
        Try a different Code
      </Link>
    </Stack>
  )
}

function ModelPageContent({ modelCode }: { modelCode: string | undefined }) {
  const [enableGrid, setEnableGrid] = useState(true);
  const [enableAO, setEnableAO] = useState(true);
  const [enableMeasure, setEnableMeasure] = useState(false);
  const viewportRef = useRef<Viewport3DRef>(null);
  return (
    // @ts-ignore
    <Box
      // @ts-ignore
      sx={{
        position: "absolute",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          width: "100%",
          height: "100%",
          position: "relative",
        }}
      >
        <ErrorBoundary
          FallbackComponent={ErrorFallback}
          onReset={() => {
            // reset the state of your app so the error doesn"t happen again
          }}
        >
          <Viewport3D
            ref={viewportRef}
            modelCode={modelCode}
            enableGrid={enableGrid}
            enableAO={enableAO}
            enableMeasure={enableMeasure}
          />
        </ErrorBoundary>
        <TopBar
          enableGrid={enableGrid}
          onEnableGrid={enable => setEnableGrid(enable)}
          enableAO={enableAO}
          onEnableAO={enable => setEnableAO(enable)}
          enableMeasure={enableMeasure}
          onEnableMeasure={enable => setEnableMeasure(enable)}
          onFitViewport={() => viewportRef.current?.fitCameraToModel()}
        />
      </Box>
    </Box>
  );
}

export default function ModelPage() {
  const themeOptions = useThemeOptions();
  const { modelCode } = useParams();
  return (
    <ThemeProvider theme={themeOptions}>
      <CollabProvider modelCode={modelCode}>
        <ModelPageContent modelCode={modelCode} />
      </CollabProvider>
    </ThemeProvider>
  );
}
